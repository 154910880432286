import { baseUrl, aiApiUrl,aiLiveUrl,baseApiVersion, headers,aiBaseUrl } from "../config/env";
import axios from "axios";
baseUrl, baseApiVersion
require("vue-i18n");


export function fetchLastResult (params){
    return axios.get(aiBaseUrl+aiApiUrl + `/last_result`, {
        params,
        headers,
      });
}

export function fetchResults (params){
  return axios.get(aiBaseUrl+aiApiUrl + `/results`, {
      params,
      headers,
    });
}

export function fetchDetectObject(params) {
    return axios.get(aiBaseUrl + aiApiUrl + `/device_type`, {
        params,
        headers
    })
}


export function fetchLastResultImage(params){
    return axios.get(aiBaseUrl +aiLiveUrl+ `/last_image`, {
        params,
        headers,
        responseType: 'blob'
      });
}

export function updateDetectedObject(data){
    return axios.put(aiBaseUrl + aiLiveUrl + `/stream/model`, data, { headers });
}

